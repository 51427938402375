import * as React from 'react';
import { Title } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

export default () => (
    <Card>
        <Title title='Settings' />
        <CardHeader title="User Settings" />
        <CardContent>Lorem ipsum sic dolor amet...</CardContent>
    </Card>
);
