import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import {
    TextInput,
    DateInput,
    Edit,
    FormWithRedirect,
    Toolbar,
    useDataProvider,
    useVersion
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import MembersList from './MembersList';
import RoomsList from './RoomsList';
import ActivityChart from './ActivityChart';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const AccountsEdit = (props,data) => {
  const dataProvider = useDataProvider();
  const [state, setState] = useState({});
  const version = useVersion();

  const fetchSessions = useCallback(async () => {
    const aid = props.id
    const days = 400 // one year + beginning of first month
    const logs = (await dataProvider.getLogs(
      `logs/accounts/${aid}/?days=${days}&filter=student-log&include=pk,aid,sid`)).data
    const sessions = logs.reduce((acc, item) => {
      const month = acc.find(x => x.month === item.pk)
      month ? month.cnt++ : acc.push({month:item.pk, cnt:1})
      return acc
    },[])
    setState(state => ({ ...state, sessions }));
  }, [dataProvider]);

  useEffect(() => {
    fetchSessions();
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    sessions
  } = state;

  return (
    <div>
      <Edit
        title={<AccountTitle />}
        component="div"
        {...props}
      >
        <AccountForm />
      </Edit>

      <div style={styles.singleCol}>
        <ActivityChart sessions={sessions} />
      </div>
    </div>
  );
};

const AccountTitle = props => 
  <span>Account: {props.record ? `${props.record.name}` : 'n/a'}
  </span>;

const AccountForm = props => {
    return (
        <FormWithRedirect
            {...props}
            render={(formProps) => (

                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>

                                    <Typography variant="h6" gutterBottom>
                                        {"Account"}
                                    </Typography>
                                    <TextInput source="name" fullWidth />
                                   
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                                        <TextInput source="plan.name" label="Plan" />
                                      </Box>
                                      {!props.record.plan.name.startsWith('free') && 
                                        <span>
                                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                                          <TextInput source="plan.seats" label="Seats" />
                                        </Box>
                                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }} >
                                          <DateInput source="plan.expiry" label="Expires" />
                                        </Box>
                                        </span>
                                      }
                                    </Box>  
                                    
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                                        <DateInput source="addedOn" disabled />
                                      </Box>
                                      {props.record?.lastUsed &&
                                      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }} >
                                        <DateInput source="lastUsed" disabled />
                                      </Box>
                                      }
                                    </Box>                         
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={true}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="accounts"
                        />
                    </form>
                    
                    <div style={styles.flex}>
                      <div style={styles.leftCol}>           
                          <div style={styles.singleCol}>
                              <MembersList
                                  members={formProps.record.members}
                              />
                          </div> 
                      </div>
                      <div style={styles.rightCol}>  
                          <div style={styles.singleCol}>
                              <RoomsList
                                  rooms={formProps.record.rooms}
                              />
                          </div> 
                      </div>
                  </div>

                </Card>
            )}
        />
    );
};

export default AccountsEdit;
