import React from 'react'
import { 
  List, 
  Datagrid, 
  TextField, 
  DateField, 
  NumberField,
  ReferenceField, 
  EditButton 
} from 'react-admin'
import UsersListAside from './UsersListAside';
import { makeStyles } from '@material-ui/core/styles';
import CustomPagination from "../../pagination";

const useStyles = makeStyles({
  nb_students: { color: 'purple' },
});

const UsersList = (props) => {
  const classes = useStyles();
  return (
    <List
      {...props}
      // filters={isSmall ? <VisitorFilter /> : null}
      // sort={{ field: 'last_seen', order: 'DESC' }}
      perPage={25}
      aside={<UsersListAside />}
      pagination={<CustomPagination />}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <TextField label="User name" source="name" />
        <TextField label="Pricing Plan" source="plan" />
        <DateField label="Added On" source="addedOn" showTime />
        <DateField label="Last Seen" source="lastUsed" showTime />
        {/* <CustomerLinkField />
        <NumberField
                      source="nb_students"
                      label="Students"
                      className={classes.nb_students}
                  />
        <DateField source="last_seen" type="date" />
        <NumberField
            source="nb_commands"
            label="resources.customers.fields.commands"
            className={classes.nb_commands}
        />
        <ColoredNumberField
            source="total_spent"
            options={{ style: 'currency', currency: 'USD' }}
        />
        <DateField source="latest_purchase" showTime />
        <BooleanField
            source="has_newsletter"
            label="News."
            size="small"
        />
        <SegmentsField /> */}
      </Datagrid>
    </List>
  )
} 

export default UsersList;