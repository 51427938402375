import React, { useState } from "react";
import { Link, Title } from 'react-admin';
import { Card, CardContent } from '@material-ui/core';
//import TextField from '@material-ui/core/TextField';
import dataProvider from '../../dataProvider'
import PersonIcon from '@material-ui/icons/Person'
import GroupIcon from '@material-ui/icons/Group'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  searchInput: { 
    width: '40%',
    "min-width": '170px'
  }
});

const Search = (props) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearch] = useState([]);
  
  const handleSearchInputChanges = (e) => {
    setSearchValue(e.target.value);
  }

  const callSearchFunction = async (e) => {
      //console.log(e) 
      e.preventDefault();
      if (!searchValue || searchValue.trim() === '') return
  
      try {
        const res = await dataProvider.search(searchValue)
        const data = res.data.map(x => ({
          type: x.ent,
          id: x.sk.substring(4),
          name: x.name,
          email: x.ent === 'user' ? x.pk: null,
          addedOn: x.added,
          lastActive: x.last
        }))
        setSearch(data)

      } catch (err) {
        return <p>ERROR: {err}</p>
      }
    };

    return (
      
    <Card title='Boo'>
      <Title title='Search' />
         <CardContent title='Boo'>
            <form onSubmit={callSearchFunction} title='Boo'>
              
              {/* <TextField placeholder="sch..." /> */}

              <input
                placeholder="Search..."
                type="text"
                className="input"
                name="searchValue"
                value={searchValue}
                onChange={handleSearchInputChanges}
                className={classes.searchInput}
              />
              <input onClick={callSearchFunction} type="submit" value="Search" />
            </form>

            <List dense={true}>
                {searchData.map(record => (
                    <ListItem
                        key={record.id}
                        button
                        component={Link}
                        to={(record.type === 'user' ? '/users/':'/accounts/') + record.id}
                    >
                        <ListItemAvatar>
                          <Avatar>
                            {record.type === 'user' ? (<PersonIcon />) : (<GroupIcon />)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={(record.type === 'user' ? 'User':'Account') + ': ' + record.name}
                            secondary={
                              (record.addedOn ? `Added: ${new Date(record.addedOn).toLocaleDateString()}, ` : '') + 
                              (record.lastActive ? `Last seen: ${new Date(record.lastActive).toLocaleDateString()}, ` : '') + 
                              (record.email ? `Email: ${record.email}` : '')}
                        />
                    </ListItem>
                ))}
            </List>
        </CardContent>
      </Card>
    )
  }

export default Search;
