import * as React from 'react';
import DollarIcon from '@material-ui/icons/AttachMoney';

import CardWithIcon from './CardWithIcon';

const getPaymentsSum = (payments) => 
    payments.reduce((acc, payment) => {
        return acc + (payment.amount/100)
    }, 0)

const RevenueCount = ({ payments }) => {
    if(!payments) return null
    return (
        <CardWithIcon
            to="/#"
            icon={DollarIcon}
            title='30-day Revenue'
            subtitle={`NZ$${getPaymentsSum(payments)}`}
        />
    );
};

export default RevenueCount;
