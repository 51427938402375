//import * as React from 'react';
import React, { useState } from "react";
//import { FC } from 'react';
import { Card as MuiCard, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOnOutlined';
//import MailIcon from '@material-ui/icons/MailOutline';
//import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import { 
    FilterList, 
    FilterListItem, 
    FilterLiveSearch, 
    TextInput,
    SimpleForm,
    Toolbar 
} from 'react-admin';
import {
    endOfYesterday,
    startOfWeek,
    subWeeks,
    startOfMonth,
    subMonths,
} from 'date-fns';
//import InputAdornment from '@material-ui/core/InputAdornment';

//import segments from '../segments/data';

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1,
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const Aside = (props) => {
    
//   const [query, setQuery] = useState("");
//   const [movies, setMovies] = useState([]);
//     const searchMovies = async (e) => {
//         console.log(e)
//         console.log(query)
//         e.preventDefault();
    
//         // const url = `https://api.themoviedb.org/3/search/movie?api_key=a2657ca16cc801deb9a65e9f7f9e3d4f&language=en-US&query=${query}&page=1&include_adult=false`;
//         // try {
//         //   const res = await fetch(url);
//         //   const data = await res.json();
//         //   //console.log(data.results);
//         //   setMovies(data.results);
//         // } catch (err) {
//         //   console.error(err);
//         // }
//       };

    return (
    <Card>
        <CardContent>

        {/* <form onSubmit={searchMovies}>
          <input
            placeholder="Search for..."
            type="text"
            className="input"
            name="query"
            //placeholder="i.e. Star Wars"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            // value={this.state.query}
            //onChange={this.handleInputChange}
            //onSubmit={doSearch}
          />
        </form> */}

            {/* <FilterLiveSearch source="name" /> */}

            <FilterList
                label="SIGNUPS"
                icon={<AccessTimeIcon />}
            > 
                <FilterListItem
                    label="Today"
                    value={{
                        added_from: endOfYesterday().toISOString(),
                        added_to: undefined,
                    }}
                />
                <FilterListItem
                    label="This week"
                    value={{
                        added_from: startOfWeek(new Date()).toISOString(),
                        added_to: undefined,
                    }}
                />
                <FilterListItem
                    label="Last week"
                    value={{
                        added_from: subWeeks(
                            startOfWeek(new Date()),
                            1
                        ).toISOString(),
                        added_to: startOfWeek(new Date()).toISOString(),
                    }}
                />
                <FilterListItem
                    label="This month"
                    value={{
                        added_from: startOfMonth(new Date()).toISOString(),
                        added_to: undefined,
                    }}
                />
                <FilterListItem
                    label="Last month"
                    value={{
                        added_from: subMonths(
                            startOfMonth(new Date()),
                            1
                        ).toISOString(),
                        added_to: startOfMonth(new Date()).toISOString(),
                    }}
                />
                <FilterListItem
                    label="Earlier"
                    value={{
                        added_from: undefined,
                        added_to: subMonths(
                            startOfMonth(new Date()),
                            1
                        ).toISOString(),
                    }}
                />
            </FilterList>

            <FilterList
                label="Account type"
                icon={<MonetizationOnIcon />}
            >
                <FilterListItem
                    label="Free trial"
                    value={{
                        plan: 'free'
                    }}
                />
                <FilterListItem
                    label="Family"
                    value={{
                        plan: 'family'
                    }}
                />
                <FilterListItem
                    label="Enterprise"
                    value={{
                        plan: 'enterprise'
                    }}
                />
            </FilterList>

        </CardContent>
    </Card>
)};

export default Aside;
