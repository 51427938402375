import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink } from 'react-admin';
//import { getResources } from 'react-admin';
//import DefaultIcon from '@material-ui/icons/ViewList';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import DashboardIcon from '@material-ui/icons/Dashboard'
import GroupIcon from '@material-ui/icons/Group'
import PaymentIcon from '@material-ui/icons/Payment'
import authProvider from '../authProvider'


let menuItems = [];
export const rebuildMenu = () => {
  const usr = true
  const adm = authProvider.hasRole('ws-*')
  menuItems = []
  
  // ordered...
  if (usr) menuItems.push({name: 'dashboard', text: 'Dashboard', path: '/', icon: <DashboardIcon />})
  if (usr) menuItems.push({name: 'resources', text: 'Resources', path: '/resources', icon: <PaymentIcon />})
  if (adm) menuItems.push({name: 'accounts', text: 'Accounts', path: '/accounts', icon: <GroupIcon />})
//if (adm) menuItems.push({name: 'users', text: 'Users', path: '/users', icon: <UserIcon />})
  if (adm) menuItems.push({name: 'search', text: 'Search', path: '/search', icon: <SearchIcon />})
  if (usr) menuItems.push({name: 'payments', text: 'License', path: '/payments', icon: <PaymentIcon />})
//if (usr) menuItems.push({name: 'settings', text: 'Settings', path: '/settings', icon: <SettingsIcon />})
}

const MyMenu = ({ onMenuClick, logout }) => {
    if (!menuItems.length) rebuildMenu();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    //const resources = useSelector(getResources);
    return (
        <div>
            <p/>
            {menuItems.map(item => (
                item ?
                <MenuItemLink
                    key={item.name}
                    to={item.path}
                    primaryText={item.text}
                    leftIcon={item.icon}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                /> : null
            ))}

            {/* 
            {resources.map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={
                        (resource.options && resource.options.label) ||
                        resource.name
                    }
                    leftIcon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                    }
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}
            <MenuItemLink
                to="/custom-route"
                primaryText="Miscellaneous"
                leftIcon={<LabelIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            /> */}

            {isXSmall && logout}
        </div>
    );
};

export default MyMenu;