import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
}));


const getSessionsByAccount = (sessions) => {
    // group
    let acts = {}
    Object.entries(sessions).map(([key,val]) => { // day data
        Object.entries(val).map(([k, v], i) => { // accounts
        let act = acts[k]
        acts[k] = {
            cnt: act ? act.cnt + v.cnt : v.cnt,
            nm: v.nm,
            last: key // last activity
        }})
    })
    // flatten
    const arr = Object.entries(acts).map(([k, v], i) => {
        return {id: k, name: v.nm, count: v.cnt, last: v.last}
    })
    // sort
    arr.sort((a, b) => b.count - a.count) // sort by count desc
    return arr
  }

const ActivityList = ({ sessions = []}) => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardHeader title="30-day Activity Data" />
            <List dense={true}>
                {getSessionsByAccount(sessions).map(record => (
                    <ListItem
                        key={record.id}
                        button
                        component={Link}
                        to={`/accounts/${record.id}`}
                    >
                        {/* <ListItemAvatar>
                            {customers[record.customer_id] ? (
                                <Avatar
                                    src={`${
                                        customers[record.customer_id].avatar
                                    }?size=32x32`}
                                />
                            ) : (
                                <Avatar />
                            )}
                        </ListItemAvatar> */}
                        <ListItemText
                            primary={record.name}
                            secondary={`last seen: ${new Date(record.last).toLocaleDateString()}`}
                        />
                        <ListItemSecondaryAction>
                            <span className={classes.cost}>
                                {record.count}
                            </span>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Card>
    );
};

export default ActivityList;
