import * as React from 'react';
import { FC } from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from 'recharts';
//import { useTranslate } from 'react-admin';

//import { Order } from '../types';

const lastDay = new Date(new Date().toDateString()).getTime();
const oneDay = 24 * 60 * 60 * 1000;
const lastMonthDays = Array.from(
    { length: 30 },
    (_, i) => lastDay - i * oneDay
).reverse();
const aMonthAgo = new Date();
aMonthAgo.setDate(aMonthAgo.getDate() - 30);

const dateFormatter = (date) =>
    new Date(date).toLocaleDateString();


const getSessionsPerDay = (sessions) => 
    lastMonthDays.map(date => {
        const key = new Date(date).toISOString().slice(0, 10)
        let dayCnt = 0
        if (sessions[key]) {
            dayCnt = Object.values(sessions[key]).reduce((acc, act) => {
                return acc + act.cnt
            }, 0)
        }
        return {
            date,
            total: dayCnt
        }
    });

const ActivityChart = ({ sessions }) => {
    if (!sessions) return null;
    return (
        <Card>
            <CardHeader title='30-day Activity History' />
            <CardContent>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={getSessionsPerDay(sessions)}>
                        <XAxis
                            dataKey="date"
                            name="Date"
                            type="number"
                            scale="time"
                            domain={[aMonthAgo.getTime(), new Date().getTime()]}
                            tickFormatter={dateFormatter}
                            reversed
                        />
                        <YAxis dataKey="total" name="Sessions" />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip
                            cursor={{ strokeDasharray: '3 3' }}
                            // formatter={value =>
                            //     new Intl.NumberFormat(undefined, {
                            //         style: 'currency',
                            //         currency: 'USD',
                            //     }).format(value)
                            // }
                            labelFormatter={(label) =>
                                dateFormatter(label)
                            }
                        />
                        <Bar dataKey="total" fill="#31708f" />
                    </BarChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default ActivityChart;
