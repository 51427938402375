import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = getApiUrl();

//const httpClient = fetchUtils.fetchJson;
const httpClient = (url, options = {}) => {
  //console.log('URL', {url, options})
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  //options.headers.set('Access-Control-Expose-Headers', 'X-Total-Count')
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);

  //return fetchUtils.fetchJson(url, options);
  return fetchUtils
    .fetchJson(url, options)
    .catch(error => {
      //Example 'error' = {"status": 404, "body": {"error": "Not found"}, "name": "HttpError"}
      //console.log('httpClient error:', JSON.stringify(error))
      return Promise.reject(error)//new HttpError((json && json.error && json.error.message) || statusText, status, json)); 
    });
}

export default {
    // ----------------------------------------------------
    // Custom API method extensions.
    // ----------------------------------------------------
    login: (username, password) => {
        return httpClient(`${apiUrl}/auth/login`, {
          method: 'POST',
          body: JSON.stringify({username, password}),
          headers: new Headers({ 'Content-Type': 'application/json' })
        //}).then(({ json }) => ({ data: json })),
        }).then((response) => {
          return response
        })
    },
    get: (path) => {
        const url = `${apiUrl}/${path}`
        //console.log('URL', url)
        return httpClient(url).then(({ headers, json }) => {
            //console.log('JSON', json)
            return {data: json}
        });
    },
    search: (query) => {
        const url = `${apiUrl}/search/?q=${query}`
        //console.log(url)
        return httpClient(url).then(({ headers, json }) => {
            //console.log(json)
            return {data: json}
        });
    },
    getLogs: (resource) => { // getList() expects array [{id:}], so custom method for /logs
        let query;
        const url = `${apiUrl}/${resource}` + (query ? `?${stringify(query)}` : '');
        return httpClient(url).then(({ headers, json }) => ({
            data: json
        }));
    },
    // -----------------------------------------------------
    // Default API as required for react-admin dataProviders
    // -----------------------------------------------------
    getList: (resource, params) => {
        let query = '';
        if (params) {
            //console.log('params', params)
            // const { page, perPage } = params.pagination;
            // const { field, order } = params.sort;
            const { next, prev, added_from, added_to, plan } = params.filter
            const limit = params.pagination.perPage
            // query = {
            //     //sort: JSON.stringify([field, order]),
            //     //range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            //     //filter: JSON.stringify(params.filter),
            //     //next: page
            // };
            const q = []
            if (next) q.push(`next=${next}`)
            else if (prev) q.push(`next=${prev}`)
            if (limit) q.push(`limit=${limit}`)
            if (added_from) q.push(`added_from=${added_from}`)
            if (added_to) q.push(`added_to=${added_to}`)
            if (plan) q.push(`plan=${plan}`)
            query = q.length > 0 ? `?${q.join('&')}` : ''
        }
        const url = `${apiUrl}/${resource}${query}`
        //const url = `${apiUrl}/${resource}` + (query ? `?${stringify(query)}` : '');
        //console.log('GET_LIST', url)
        return httpClient(url).then(({ headers, json }) => {
            //console.log('JSON', json)
            return (json && json.items) ? {
                data: json.items,
                total: json.count,
                cursors: {next: json.next, prev: json.prev}
            } : {
                data: json,
                total: json.length
            }
        });
    },

    getOne: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`)
            .then(({ json }) => {
                //console.log('JSON', json)
                return {
                    data: json,
                }
            })
            //.catch((er) => {console.log('Error dataProvider() ', er)})
        },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        //console.log('GET_MANY', url)
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        //console.log('CREATE', {url:`${apiUrl}/${resource}`, body: params.data})
        //console.log('PARAMS', JSON.stringify(params))
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data)
        }).then(({ json }) => {
            if ((JSON.stringify(json) === JSON.stringify({status: 'SUCCESS'})))
              json.id = -999 // dummy id to satisfy react-admin
            return {data: { ...params.data, ...json }} // merge
        })//.catch((er) => {console.log('Error dataProvider() ', er)})
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
};

function getApiUrl() {
  // Can't use .env vars on S3 static site, so use brower URL
  const hostname = window.location.hostname

  if (hostname === 'localhost') {
    return 'https://api.dev.wordsmart.app'
    //return 'https://api.wordsmart.app'
  }
  else {  
    // portal.test.wordsmart.app -> api.test.wordsmart.app
    return 'https://' + hostname.replace('portal', 'api')
  }
}