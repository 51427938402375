//import * as React from "react";
import { Layout } from 'react-admin';
import { useSelector } from 'react-redux';
//import AppBar from './AppBar';
import { darkTheme, lightTheme } from './themes';
import MyMenu from './MyMenu'

const MyLayout = (props) => {
    const theme = useSelector((state) =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );
    return (
        <Layout
            {...props}
            // appBar={AppBar}
            menu={MyMenu}
            theme={theme}
        />
    );
};

export default MyLayout;

