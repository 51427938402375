import * as React from 'react';
import WalkIcon from '@material-ui/icons/DirectionsWalk';

import CardWithIcon from './CardWithIcon';

const getCount = (sessions) => 
    Object.values(sessions).reduce((acc, val) => {     
        return acc += Object.values(val).reduce((acc2, curr2) => {
            return acc2 += curr2.cnt
        }, 0)
    }, 0)

const ActivityCount = ({ sessions }) => {
    if (!sessions) return null
    return (
        <CardWithIcon
            to="/commands"
            icon={WalkIcon}
            title='Customer Activity'
            subtitle={getCount(sessions)}
        />
    );
};

export default ActivityCount;
