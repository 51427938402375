import {
  Typography,
  Card,
  CardContent,
  Paper,
  Grid,
  Button
} from '@material-ui/core';
import ReactPlayer from 'react-player/youtube';

const Resources = () => {
  { // render...
    return (
      <Card>
        <CardContent style={{ fontSize: '1.2em' }}>

          <Typography variant="h4" gutterBottom>
            Additional resources to accompany the Wordsmart App
          </Typography>

          <Paper style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h5" gutterBottom>
                  Wordsmart Story Book
                </Typography>
                Get the Wordsmart App stories in book form. Each story includes questions and a drawing exercise,
                and we also recommend that children to act out the stories!
                <div style={{ marginTop: 15 }}>
                  <Button variant='contained' component="label">
                    <a href='https://cdn.wordsmart.app/resources/Story+Book+A+-+E.pdf'
                      target={'_blank'}>Download (PDF)</a>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={6}>
                <img src='https://cdn.wordsmart.app/resources/Story+Book+A+-+E.png'
                  style={{ width: 'auto', height: '200px' }} />
              </Grid>
            </Grid>
          </Paper>

          <Paper style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h5" gutterBottom>
                  Letter Tracing Flash Cards
                </Typography>
                Hands-on tactile tools to reinforce the learning within the app.
                <div style={{ marginTop: 15 }}>
                  <Button variant='contained' component="label">
                    <a href='https://cdn.wordsmart.app/resources/Letter+Tracing+Flash+Card+(Uppercase).pdf'
                      target={'_blank'}>Uppercase (PDF)</a>
                  </Button><br />
                  <Button variant='contained' component="label">
                    <a href='https://cdn.wordsmart.app/resources/Letter+Tracing+Flash+Card+(Lowercase).pdf'
                      target={'_blank'}>Lowercase (PDF)</a>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={6}>
                <img src='https://cdn.wordsmart.app/resources/Letter+Tracing+Flash+Card.png'
                  style={{ width: 'auto', height: '200px' }} />
              </Grid>
            </Grid>
          </Paper>

          <Paper style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h5" gutterBottom>
                  ABC NameSound Check
                </Typography>
                This will help you gain a good understanding of how a child is mastering the basics.
                <div style={{ marginTop: 15 }}>
                  <Button variant='contained' component="label">
                    <a href='https://cdn.wordsmart.app/resources/ABC+NAME+SOUND+CHECK.pdf'
                      target={'_blank'}>Download (PDF)</a>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={6}>
                <img src='https://cdn.wordsmart.app/resources/ABC+NAME+SOUND+CHECK.png'
                  style={{ width: 'auto', height: '200px' }} />
              </Grid>
            </Grid>
          </Paper>

          <Paper style={{ padding: 20, marginBottom: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h5" gutterBottom>
                  Kids Personality Quiz
                </Typography>
                A fun and informative quiz with amazingly accuracy. Try it on yourself as well!
                <div style={{ marginTop: 15 }}>
                  <Button variant='contained' component="label">
                    <a href='https://cdn.wordsmart.app/resources/Kids+Quiz.pdf'
                      target={'_blank'}>Download (PDF)</a>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={6}>
                <img src='https://cdn.wordsmart.app/resources/Kids+Quiz.png'
                  style={{ width: 'auto', height: '200px' }} />
              </Grid>
            </Grid>
          </Paper>

          <Paper style={{ padding: 20, marginBottom: 20 }} gutterBottom>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Typography variant="h5" gutterBottom>
                  Prolexia - introduction
                </Typography>

                All of the above resources can also be found in our Prolexia online course,
                alongside further comprehensive content designed to extend your child or students' reading proficiency.
                Click below to find out more about Prolexia and receive our special launch offer

                <div style={{ marginTop: 15, marginBottom: 15 }}>
                  <Button variant='contained' component="label">
                    <a href='https://wordsmart.app/offer'
                      target={'_blank'}>Special offer</a>
                  </Button>
                </div>

                <ReactPlayer url='https://youtu.be/4n__ieJBEjM' />

              </Grid>
            </Grid>
          </Paper>
        </CardContent>
      </Card>
    )
  }
};

export default Resources;