import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  required,
  minLength,
  Title,
  TextInput, 
  SimpleForm,
  useVersion, 
  useNotify,
  useDataProvider,
  RadioButtonGroupInput,
  SaveButton,
  Toolbar
} from 'react-admin';
import { 
  Card, 
  CardContent
} from '@material-ui/core';


const InviteAccept = (staticContext, props) => {
  const dataProvider = useDataProvider();
  const [state, setState] = useState({});
  const version = useVersion();
  const notify = useNotify();

  //https://marmelab.com/react-admin/doc/2.9/CreateEdit.html#validation
  const validateName = [required(), minLength(4)];
  const validatePass = [required(), minLength(8)];
  const validateType = [required()]

  function getUrlParam() {
    return window.location.href.match(new RegExp('(?<=\invites\/).+?(?=\/accept)'))
  }
  
  const fetchInvite = useCallback(async (id) => {
    try {
      const invite = (await dataProvider.getOne(`invites`, {id})).data 
      setState(state => ({ ...state, invite }));
    } catch(error) {
      setState(state => ({ ...state, error }));
      notify(`Error: ${error.body?.error || error}`, 'warning')
    }
  }, [dataProvider]);

  const submit = useCallback(async (values) => {
    try {
      const { data } = await dataProvider.create(`invites/${values.id}/accept`, {
        data: { // only requiured for new users
          name: values._fullname,
          password: values._password,
          type: values._type
      }});
      window.location.reload(); // refresh page
    } catch(error) {
      notify(`Error: ${error.body?.error || error}`, 'warning')
    }
  }, [dataProvider]);

  useEffect(() => {
    fetchInvite(getUrlParam())
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    invite,
    error
  } = state;

  if (error) return (<p>Error: {error.body.error}</p>)
  if (!invite) return null;

  const isProcessed = invite?.processed || invite?.ttl
  const isNewUser = !invite?.invitee

  if (isProcessed) return (
    <Card>
      <Title title="Invite to Wordsmart" />
      <CardContent>
        <h1>Invite processed</h1>
        <p>
          Please login to the Wordsmart App on any of these platforms:
          <ul>
            <li><a href="https://web.wordsmart.app/" target="blank_">Open the Web version</a></li>
            <li><a href="https://apps.apple.com/nz/app/wordsmart-kids/id1519189284" target="blank_">View on Apple store</a></li>
            <li><a href="https://play.google.com/store/apps/details?id=com.wordsmart.wordsmart" target="blank_">View on Android store</a></li>
          </ul>
        </p>
        <br/>
        For more information about Wordsmart <a href="https://wordsmart.app/" target="_blank">visit our website</a>.
      </CardContent>
    </Card>
  )
  else if (isNewUser) return (
    <Card>
    <Title title="Invite to Wordsmart" />
    <CardContent>
      <h2>Welcome to Wordsmart</h2> 
      <p>
        Please create an account to accept the invite from {invite.inviterName}.
        </p>
        <SimpleForm record={invite} save={submit} toolbar={<FormToolbar />} redirect="show">         
        <TextInput source="email" label="Invite email" disabled/>                 
        <TextInput source="_fullname" label="Your full name" validate={validateName} />       
        <TextInput source="_password" label="Choose a password" type="password" validate={validatePass}/>
            <RadioButtonGroupInput source="_type" label="I am a..." validate={validateType} 
              choices={[{ id: 'parent', name: 'Caregiver' },
                        { id: 'teacher', name: 'Educator' },
          ]}/>
        </SimpleForm>
      <br/>
      For more information about Wordsmart <a href="https://wordsmart.app/" target="_blank">visit our website</a>.
      </CardContent>
    </Card>
  )
  else return (
    <Card>
      <Title title="Invite to Wordsmart" />
      <CardContent>
        <h2>Accepting invite</h2> 
        <p>
          Please submit this form to accept the invite from {invite.inviterName}.
        </p>
        <SimpleForm record={invite} save={submit} toolbar={<FormToolbar />} redirect="show">
        </SimpleForm>
        <br/>
        For more information about Wordsmart <a href="https://wordsmart.app/" target="_blank">visit our website</a>.
      </CardContent>
    </Card>
  )
}

export default InviteAccept;

const FormToolbar = props => (
  <Toolbar {...props} >
    <SaveButton
      redirect="show"
      submitOnEnter={true}
      label="Submit"
    />
  </Toolbar>
);
