import * as React from 'react'
import { Admin, Resource } from 'react-admin'
import Dashboard from './components/dashboard/dashboard'
import accounts from './components/accounts';
import users from './components/users';
import Search from './components/search'
import Resources from './components/resources'
import Payments from './components/payments'
import Settings from './components/settings'
import dataProvider from './dataProvider'
import authProvider from './authProvider'
import MyLayout from './layout/MyLayout';
import pageCursorReducer from "./reducers/pageCursorReducer";
import './App.css';
import customRoutes from './customRoutes';
import CustomLogin from './components/CustomLogin'
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
const i18nProvider = polyglotI18nProvider(locale => englishMessages, 'en', { allowMissing: true });

const App = () => (
  <Admin 
    loginPage={CustomLogin}
    i18nProvider={i18nProvider}
    layout={MyLayout}
    authProvider={authProvider} 
    dataProvider={dataProvider}
    customReducers={{ cursors: pageCursorReducer }} 
    dashboard={Dashboard}
    customRoutes={customRoutes}
  >
    { permissions => {

      const resources = [
        // user routes
        <Resource name="resources" list={Resources} />,
        <Resource name="payments" list={Payments} />,
        <Resource name="settings" list={Settings} />,
        <Resource name="InviteAccept" /> 
      ];

      if (authProvider.hasRole('ws-*', permissions)) {
        resources.push([
          // ws-staff-only routes
          <Resource name="accounts" {...accounts} />,
          <Resource name="users" {...users} />,
          <Resource name="search" list={Search} />
        ]);
      }

      return resources;
    }}
  </Admin>
)

export default App;