import * as React from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from 'recharts';

function pad(x) {
  return x < 10 ? `0${x}` : x
}

const end = new Date(); // today
const start = new Date(end.getFullYear()-1, end. getMonth(), 1); // first of month a year ago
const getSessionsPerMonth = (sessions) => {
    const months = []
    var x = new Date(start)
    while(x < end) {
        const date = `${x.getFullYear()}-${pad(x.getMonth()+1)}`
        const s = sessions.find(a => a.month+'' === date)
        const total = s?.cnt || 0
        months.push({date, total})
        x.setMonth(x.getMonth()+1)
    }
    return months
}

const dateFormatter = (date, showYear) => {
    date = new Date(date)
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']    
    return months[date.getMonth()] + 
        (showYear ? ' ' + date.getFullYear() : '');
}

const ActivityChart = ({ sessions }) => {
    if (!sessions) return null;
    return (
        <Card>
            <CardHeader title='12 month activity' />
            <CardContent>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={getSessionsPerMonth(sessions)}>
                        <XAxis
                            dataKey="date"
                            name="Month"
                            tickFormatter={dateFormatter}
                            reversed
                        />
                        <YAxis 
                            dataKey="total" 
                            name="Sessions" />
                        <CartesianGrid 
                            strokeDasharray="3 3" />
                        <Tooltip
                            cursor={{ strokeDasharray: '3 3' }}
                            labelFormatter={(label) =>
                                dateFormatter(label, true)
                            }
                        />
                        <Bar dataKey="total" fill="#31708f" />
                    </BarChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default ActivityChart;
