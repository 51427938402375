import * as React from 'react';
import { useState } from "react";
import { 
  IconButton,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  Button,
  Select,
  MenuItem
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { 
  Labeled,
  useDataProvider
} from 'react-admin'
import StripeIcon from './../../images/stripe.svg'
import { loadStripe } from '@stripe/stripe-js';
import { makeStyles } from '@material-ui/core/styles';
import { useNotify, useSafeSetState } from 'ra-core';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(window.location.hostname.includes('portal.wordsmart') ?
  'pk_live_w5xpWKmA9aNa4w7xzQyV1GGH00yNyLDrue' : 
  'pk_test_rHUj3WjemH3Vm7POqwJxcLTF00A4zR6hyB'
);

const useStyles = makeStyles(
  (theme) => ({//}): Theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaLoginForm' }
);

const LicenseSelector = ({ props, license, plans, accountId }) => {
  const notify = useNotify()
  const [count, setCount] = useState(0)
  const [currency, setCurrency] = useState('nzd')
  const [loading, setLoading] = useSafeSetState(false)
  const classes = useStyles(props)
  const dataProvider = useDataProvider()
  if (!plans) return null

  // backwards compat for missing license plan id
  if (license && !license.id) {
    license.id = plans.find(x => x.id = license.name).id
  }

  const currencies = Object.keys(plans[0].prices).map(k => ({
    key: k, val: (
      k === 'nzd' ? 'New Zealand' :
      k === 'aud' ? 'Australia' :
      k === 'gbp' ? 'United Kingdom' :
      k === 'usd' ? 'United States' : '')
  })).sort((a,b) => (a.val > b.val))

  const increment = () => {
    setCount((c) => c + 1)
  };
  const decrement = () => {
    if (count > 0)
      setCount((c) => c - 1)
  };

  const handleSetCurrency = (val) => {
    setCurrency(val.props.value)
  }

  const submit = async() => {
    const bestPlan = parsePlans(plans, license, count, currency)
    const payload = {
      aid: accountId,
      cnt: count,
      cst: bestPlan.costFinal,
      exp: bestPlan.expDate,
      pln: bestPlan.plan.id,
      cur: bestPlan.currency,
    }
    try {
      setLoading(true);
      const res = await dataProvider.create(`payments/sessions`, {data: payload});
      const {data} = res

      // Redirect to stripe checkout.
      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({sessionId: data.id});  
      
      setLoading(false);
      if (result.error) {
        notify(`Failed to direct to payment page: ${result.error.message}`, 'warning')
        return
      }
    } catch(error) {
      setLoading(false);
      notify(`Error: ${error.body?.error || error}`, 'warning')
    }
  }

  return (
    <div >
      <div>
        <Labeled label="Choose currency" /><br/>
        <Select
          style={{marginTop:'-1em', marginBottom:'2em', width:'150px'}}
          labelId="cur-sel"
          id="cur-select"
          value={currency}
          label='Currency'
          onChange={(x,y) => handleSetCurrency(y)} 
        >
          {currencies.map(x => (<MenuItem key={x.key} value={x.key}>{x.val}</MenuItem>))}
        </Select>
      </div>
      <Labeled label="Add students" />
      <div style={{marginTop:'-.5em'}}>
          <OutlinedInput
            id="filled-adornment-password"
            //style={{ height:50, fontSize: "2em", width:150, textAlign: "center" }}
            inputProps={{ style: {textAlign: 'center',height:25, fontSize: "1.7em", width:50} }}
            value={count}
            //validate={validatePlan}
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  size="small"
                  aria-label="-"
                  onClick={decrement}
                  edge="start"
                  //style={{marginLeft:'-1em'}}
                >
                  <RemoveIcon />
                </IconButton>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  aria-label="+"
                  onClick={increment}
                  edge="end"
                >
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            }
          />          
        <PriceDisplay 
          plans={plans} 
          license={license} 
          count={count}
          currency={currency}
          style={{marginLeft:'.5em', paddingTop:'3em', fontSize:'1.7em', color:'gray'}} 
        />
      </div>
      <div style={{paddingTop:'1em'}}>
        <Labeled label="Purchase details">
          <TotalDisplay 
              plans={plans} 
              license={license} 
              count={count} 
              currency={currency}
            />
        </Labeled>
      </div>
      <div style={{paddingTop:'1em'}}>
        <Button
          style={{width:200}}
          variant="contained"
          onClick={submit}
          color="primary"
          disabled={loading}
          className={classes.button}
          disabled={count===0}
        >
          {loading && (
            <CircularProgress
              className={classes.icon}
              size={18}
              thickness={2}
            />
          )}
          Checkout
        </Button>  
        <a href="https://stripe.com" target="_blank">    
          <img src={StripeIcon} alt="stripe.com" width="100" style={{verticalAlign:'middle', marginLeft:'1em'}}/>
        </a>
      </div>
    </div>
  );
};

// NOTE: this function is copied as-is on server to check results against manipulation
const parsePlans = ( allPlans, currentLicense, newSeats, currency) => {
  // == start copy ==========================================================
  // NOTE: this function is copied as-is on server to check results against manipulation
  const now = new Date()
  const isExpired = !currentLicense?.expiry || new Date(currentLicense.expiry) < new Date()
  const totSeats = ((!isExpired && currentLicense?.seats) || 0) + newSeats
  let currentPlan, bestPlan
  for (const plan of allPlans) {
    const seats = ((totSeats <= plan.min) ? plan.min : totSeats) || 0
    const costBase = seats * plan.prices[currency] / 100
    const thisPlan = { seats, costBase, plan }

    if (!isExpired && plan.id === (currentLicense.id||currentLicense.name)) currentPlan = plan
    if (!bestPlan || costBase < bestPlan.costBase) bestPlan = thisPlan
  }

  // set price to use in calcs (overrides the 'old' single currency price property)
  if (currentPlan) currentPlan.price = currentPlan.prices[currency]

  // less existing plan
  const prevSeats = !currentPlan ? 0 :
    (currentLicense.seats || 0) < currentPlan.min ? currentPlan.min : (currentLicense.seats || 0)
  const prevCost = !currentPlan ? 0 :
    (currentPlan?.price || 0) * prevSeats / 100

  // less months to expiry
  const startDate = new Date(new Date(now.toISOString().substr(0,10)))//.setDate(1))
  const endDate = !isExpired
    ? new Date(currentLicense.expiry)
    : new Date(new Date(new Date(now.toISOString().substr(0,10)).setYear(now.getFullYear()+1)))//.setDate(1))

  let months = 0
  if (isExpired) months = 12
  else for (var d=startDate; d<endDate; d.setMonth(d.getMonth()+1)) { months++ }

  bestPlan = {
    monthsToExp: months,
    expDate: endDate.toISOString().substr(0,10),
    expMonths: months,
    costFinal: newSeats === 0 ? 0 : Number(((bestPlan.costBase - prevCost) * months / 12).toFixed(2)),
    //costPrev: prevCost,
    seats: newSeats === 0 ? 0 : bestPlan.seats,
    newSeats,
    currency,
    //costBase: bestPlan.costBase,
    plan: bestPlan.plan
  }
  //console.log({currentLicense, newSeats, bestPlan})
  return bestPlan
  // == end copy ==========================================================
}

const PriceDisplay = ({ plans, license, count, currency, style }) => {
  const x = parsePlans(plans, license, count, currency)
  return (
    <span style={style}>{currency==='gbp'?'£':'$'}{x.costFinal} on {x.plan.name} Plan</span>
  )
}

const TotalDisplay = ({ plans, license, count, currency, style }) => {
  const x = parsePlans(plans, license, count, currency)
  const months = x.monthsToExp
  const seatsMin = x.seats === x.plan.min && x.plan.min > 0 ? '(min)':''
  //const expStr = license.expiry ? new Date(license.expiry) : new Date()).toLocaleDateString()
  const planStr = count == 0 ? '' :
    `${x.plan.name} with ${x.seats} ${seatsMin} students `
  
  return (
    <span style={style}>
      <b>New plan</b>: {x.plan.name} with {x.seats} {seatsMin} students to {new Date(x.expDate).toLocaleDateString()} <br/>
      <b>Total cost</b>: {currency==='gbp'?'£':'$'}{x.costFinal.toFixed(2)} {currency.toUpperCase()} {months < 12 ? `(adjusted for ${months} month expiry)` : ''}
    </span>
  )
}

const ComparePlans = ({ plans, license, count }) => {
  const bestPlan = parsePlans(plans, license, count)
  return (
    <div>
      {plans.map(plan => {
        const fontWeight = plan.id === bestPlan.id ? 'bold' : 'normal'
        const seatsStr = !(plan._totSeats <= plan.min) // within 'min' seats for plan
          ? ((license.seats||0) > 0 ? `${license.seats||0} + ` : '') + `${count}` 
          : `${plan.min} (min)`
        const str = `${plan.name} Plan: $${plan._totCost} for ${seatsStr} students ($${plan.price/100} each)`

        return <li key={plan.id} style={{fontWeight, marginLeft:'1em'}}>{str}</li>
      })}
    </div>
  );
};

export default LicenseSelector;
