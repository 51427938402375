import * as React from 'react';
import { FC } from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from 'recharts';
//import { useTranslate } from 'react-admin';

//import { Order } from '../types';

const lastDay = new Date(new Date().toDateString()).getTime();
const oneDay = 24 * 60 * 60 * 1000;
const lastMonthDays = Array.from(
    { length: 30 },
    (_, i) => lastDay - i * oneDay
).reverse();
const aMonthAgo = new Date();
aMonthAgo.setDate(aMonthAgo.getDate() - 30);

const dateFormatter = (date) =>
    new Date(date).toLocaleDateString();

const getSignupsPerDay = (signups) => 
    lastMonthDays.map(date => {
        const key = new Date(date).toISOString().slice(0, 10)
        return {
            date,
            total: signups[key] ? signups[key].length : 0 // daysWithSignups[date] || 0,
        }
    });

const SignupsChart = ({ signups }) => {
    //const translate = useTranslate();
    if (!signups) return null;

    return (
        <Card>
            <CardHeader title='30-day Signup History' />
            <CardContent>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={getSignupsPerDay(signups)}>
                        <XAxis
                            dataKey="date"
                            name="Date"
                            type="number"
                            scale="time"
                            domain={[aMonthAgo.getTime(), new Date().getTime()]}
                            tickFormatter={dateFormatter}
                            reversed
                        />
                        <YAxis dataKey="total" name="Signups" allowDecimals={false} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip
                            cursor={{ strokeDasharray: '3 3' }}
                            // formatter={value =>
                            //     new Intl.NumberFormat(undefined, {
                            //         style: 'currency',
                            //         currency: 'USD',
                            //     }).format(value)
                            // }
                            labelFormatter={(label) =>
                                dateFormatter(label)
                            }
                        />
                        <Bar dataKey="total" fill="#31708f" />
                    </BarChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default SignupsChart;
