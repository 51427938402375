import React, {
} from 'react';
import {
    TextInput,
    DateInput,
    SelectInput,
    Edit,
    FormWithRedirect,
    Toolbar,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import AccountsList from './AccountsList';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};
const UsersEdit = props => {

  return (
      <Edit
        title={<UserTitle />}
        component="div"
        {...props}
      >
        <UserForm />
      </Edit>
  );
};

const UserTitle = props => 
  <span>User: {props.record ? `${props.record.name}` : 'n/a'}</span>;

const UserForm = props => {
    return (
        <FormWithRedirect
            {...props}
            render={(formProps) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>

                                    <Typography variant="h6" gutterBottom>
                                        {"User"}
                                    </Typography>
                                    <TextInput source="name" fullWidth />
                                    <TextInput source="email" fullWidth disabled='true' />
                                    {/* <Box display={{ xs: 'block', sm: 'flex' }}>
                                      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                                        <SelectInput
                                          source="plan"
                                          choices={[
                                            { id: 'free1', name: 'Free 1' },
                                            { id: 'free2', name: 'Free 2' },
                                            {
                                                id: 'unknown',
                                                name: 'unknown',
                                                disabled: true,
                                              },
                                            ]}
                                          />
                                      </Box>
                                      <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                                          <DateInput source="addedOn" disabled />
                                      </Box>
                                      <Box flex={1} ml={{ xs: 0, sm: '0.5em' }} >
                                          <DateInput source="lastUsed" disabled />
                                      </Box>
                                    </Box> */}
                                         
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={true}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="users"
                        />
                    </form>

                    <div style={styles.flex}>
                      <div style={styles.leftCol}>           
                          <div style={styles.singleCol}>
                              <AccountsList
                                  accounts={formProps.record.allAccounts}
                              />
                          </div> 
                      </div>
                      {/* <div style={styles.rightCol}>  
                          <div style={styles.singleCol}>
                              <RoomsList
                                  rooms={formProps.record.rooms}
                              />
                          </div> 
                      </div> */}
                  </div>

                </Card>
            )}
        />
    );
};

export default UsersEdit;
