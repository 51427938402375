import React from 'react'
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
  },
  cost: {
    marginRight: '1em',
    color: theme.palette.text.primary,
  },
}));

const PaymentsList = (payments) => {
  const classes = useStyles();
  if (!payments) return null
  payments = payments.payments // WTF
  payments = payments.sort((a, b) => a.addedOn < b.addedOn)
  return (
    <Card className={classes.root}>
      <List dense={true}>
        {payments.map(record => (
          <ListItem
            key={record.id}
            button
          //component={Link}
          //to={`/payment/${record.id}`}
          >
            <ListItemText
              primary={`${new Date(record.addedOn).toLocaleString()}`}
              secondary={record.name}
            />
            <ListItemSecondaryAction>
              <span className={classes.cost}>
                {`${record.currency === 'gbp' ? '£' : '$'}${record.amount / 100} ${(record.currency || 'nzd').toUpperCase()}`}
              </span>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Card>
  )
}

export default PaymentsList;