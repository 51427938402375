import { Button, Toolbar } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router-dom';

import { connect } from "react-redux";

const CustomPagination = (props) => {
    const next = props.cursors ? props.cursors.next : null
    const prev = props.cursors ? props.cursors.prev : null
    const history = useHistory();

    function setPage (cursor) {
        //history.push({ search: '' });
        props.setFilters(cursor) // HACK using filters to store cursor info

        history.push({ search: '' });
        // history.push({
        //     search: `?${stringify({
        //         ...newParams,
        //         filter: JSON.stringify(newParams.filter),
        //         displayedFilters: JSON.stringify(newParams.displayedFilters),
        //     })}`,
        // });
    }

    if (!next && !prev)
        return null;

    return (
      <Toolbar>
        {prev && (
          <Button
            color="primary"
            key="prev"
            icon={<ChevronLeft />}
            onClick={() => setPage({prev})}//props.setFilters({prev})}
          >
            Prev
          </Button>
        )}
        {next && (
          <Button
            color="primary"
            key="next"
            icon={<ChevronRight />}
            onClick={() => setPage({next})}//props.setFilters({next})}
            labelposition="before"
          >
            Next
          </Button>
        )}
      </Toolbar>
    );
}
const mapStateToProps = state => ({ cursors: state.cursors });
export default connect(mapStateToProps)(CustomPagination);
