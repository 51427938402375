import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Labeled,
  TextField,
  useVersion, 
  useAuthProvider,
  useDataProvider,
} from 'react-admin';
import { 
  Typography,
  Link,
  Card,
  CardContent
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LicenseSelector from './LicenseSelector';
import PaymentsList from './PaymentsList';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaLoginForm' }
);

const StripeContainer = props => {
  const authProvider = useAuthProvider();
  const dataProvider = useDataProvider();
  const [state, setState] = useState({});
  const version = useVersion();
  const classes = useStyles(props);

  function getQueryParam(name) {
    var q = window.location.href.match(new RegExp('[?&]' + name + '=([^&#]*)'));
    return q && q.length && decodeURI(q[1]).trim();
  }

  const fetchPlans = useCallback(async () => {
    const {data} = await dataProvider.get('payments/plans')   
    setState(state => ({ ...state, plans: data.plans }));
  }, [dataProvider]);

  const fetchPayments = useCallback(async () => {
    const {data} = await dataProvider.get('payments')   
    setState(state => ({ ...state, payments: data }));
  }, [dataProvider]);

  const licenseSummary = () => {
    const lic = user.currentAccount.plan

    if (lic.name === 'trial') return "Trial (unlimited medium-term trial)"

    const nm = lic.name
      .charAt(0).toUpperCase() + lic.name.slice(1) // uppercase first char
      .replace(/[0-9]/g, '')  // remove numbers (eg 'free1')

    const seatsStr = lic.seats 
      ? ` with ${lic.seats} students`
      : ''
    const exp = user.currentAccount.plan.expiry 
      ? `, expiring ${new Date(user.currentAccount.plan.expiry).toLocaleDateString()}`
      : ''
    return `${nm} Plan ${seatsStr} ${exp}`
  }

  useEffect(() => {
    if (!user) return
    fetchPlans();
    fetchPayments();
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    plans,
    payments,
  } = state;
  const user = authProvider.getCurrentUser();

  { // render...
    if (!user) return null;
    const statusParam = getQueryParam('status');
    const planParam = getQueryParam('plan');
    if (user && plans?.find(x => x.id === planParam)) user._planId = planParam;
    
    if (statusParam === 'success') {
      // refresh for new plan
      authProvider.refreshUser();
      
      return (
        <div>
          <Typography variant="h6" gutterBottom>
            Payment successful
          </Typography>
          <Typography gutterBottom>
          Thank you for your payment.<br/>
          A confirmation email has been sent to you.
          </Typography>
        </div>)
    }
    else if (statusParam === 'cancelled') {
      return (
        <div>
          <Typography variant="h6" gutterBottom>
            Payment cancelled
          </Typography>
          <Typography gutterBottom>
            The payment was <b>not</b> processed.
          </Typography>
        </div>)
    }
    else return (
      <Card>
        <CardContent>
          <div className={classes.form}>
            <Typography variant="h6" gutterBottom>Wordsmart subscriptions</Typography>
            <Labeled label="Account name" fullWidth>
              <TextField source="currentAccount.name" record={user} />
            </Labeled>
            <Labeled label="Email" fullWidth>
              <TextField source="email" record={user}/>
            </Labeled>
            <Labeled label="Current license" fullWidth>
              <TextField source="t" record={{t:licenseSummary()}}/>
            </Labeled>
            <Typography variant="h6" gutterBottom style={{marginTop:'1em'}}>Edit License</Typography>

            <div  style={{marginBottom:'1em'}}>
              <OpenInNewIcon style={{ fontSize: 'small', marginRight: '5px', verticalAlign: 'middle' }} />
              <Link href="https://wordsmart.app/pricing" variant="body2" target="_blank">
                View detailed pricing page
              </Link>
            </div>
            
            {(user.currentAccount?.plan?.name !== 'trial') && (
              <LicenseSelector 
                props={props}
                plans={plans} 
                license={user.currentAccount.plan} 
                accountId={user.currentAccount.id} />
            )}
          
            {payments && (<div>
              <Typography variant="h6" gutterBottom style={{marginTop:'2em'}}>Payments</Typography>
              <PaymentsList
                payments={payments}
              />
            </div>)}
          </div>
        </CardContent>
      </Card>
    )
  };
};
export default StripeContainer;