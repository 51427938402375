import * as React from 'react';
import PersonIcon from '@material-ui/icons/PersonAdd';

import CardWithIcon from './CardWithIcon';

const getSignupsCount = (signups) => 
    Object.values(signups).reduce((acc, val) => {
        return acc + val.length
    }, 0)

const SignupsCount = ({ signups }) => {
    if(!signups) return null

    return (
        <CardWithIcon
            to="/commands"
            icon={PersonIcon}
            title='New Signups'
            subtitle={getSignupsCount(signups)}
        />
    );
};

export default SignupsCount;
