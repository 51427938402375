import React, {
    useState,
    useEffect,
    useCallback
} from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import SignupsCount from './SignupsCount';
import SignupsChart from './SignupsChart';
import SignupsList from './SignupsList';
import ActivityCount from './ActivityCount';
import ActivityChart from './ActivityChart';
import ActivityList from './ActivityList';
import RevenueCount from './RevenueCount';
import Welcome from './Welcome';
import DashboardClients from './dashboard-clients';
import authProvider from '../../authProvider';

const Spacer = () => <span style={{ width: '1em' }} />;
const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Dashboard = () => {
    const dataProvider = useDataProvider();
    const [state, setState] = useState({});
    const version = useVersion();

    const fetchSignups = useCallback(async () => {
        if (!authProvider.hasRole('ws-*')) return
        const signups = (await dataProvider.getLogs('logs/signups')).data       
        setState(state => ({ ...state, signups }));
    }, [dataProvider]);

    const fetchSessions = useCallback(async () => {
        if (!authProvider.hasRole('ws-*')) return
        const sessions = (await dataProvider.getLogs('logs/sessions')).data
        setState(state => ({ ...state, sessions }));
    }, [dataProvider]);

    const fetchPayments = useCallback(async () => {
        if (!authProvider.hasRole('ws-*')) return
        const payments = (await dataProvider.getLogs('logs/payments')).data
        setState(state => ({ ...state, payments }));
    }, [dataProvider]);

    useEffect(() => {
        fetchSignups();
        fetchSessions();
        fetchPayments();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        signups,
        sessions,
        payments
    } = state;

    return (
        <>
            <Welcome />
            {authProvider.hasRole('ws-*') ?
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <SignupsCount signups={signups} />
                        <Spacer />
                        <RevenueCount payments={payments} />
                    </div>
                     <div style={styles.singleCol}>
                        <SignupsChart signups={signups} />
                    </div>
                    <div style={styles.singleCol}>
                        <SignupsList
                            signups={signups}
                            //customers={pendingOrdersCustomers}
                        />
                    </div> 
                </div>
                <div style={styles.rightCol}>
                    <div style={styles.flex}>
                        <ActivityCount sessions={sessions} />
                        <Spacer />
                        {/* <ActivityCount value='NZ$0.00' /> */}
                    </div>
                     <div style={styles.singleCol}>
                        <ActivityChart sessions={sessions} />
                    </div>
                    <div style={styles.singleCol}>
                        <ActivityList
                            sessions={sessions}
                            //customers={pendingOrdersCustomers}
                        />
                    </div> 
                </div>
            </div>
            : 
                <div>
                    <DashboardClients />
                </div>
             }
        </>
    );
};

export default Dashboard;
