import { Login } from 'react-admin';
import CustomLoginForm from './CustomLoginForm'

const CustomLogin = (props) => {
  //browserHistory.push({pathname: '/thing', search: 'search=thing'})//location.search})
  return (
  <Login backgroundImage="splashScreen.jpg">
    <CustomLoginForm {...props} />
  </Login>
)}

export default CustomLogin;