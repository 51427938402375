// Straight copy of RA login form: https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/auth/LoginForm.tsx

import * as React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useNotify, useSafeSetState } from 'ra-core';
import {
  useDataProvider, 
  useRedirect
} from 'react-admin'
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(
  (theme) => ({//}): Theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaLoginForm' }
);

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const LoginForm = (props) => {//}: Props) => {
  const { state: data } = useLocation();
  const userEmail = data.email
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);

  const validate = (values) => {//}: FormData) => {
    const errors = { code: undefined, password: undefined };

    if (!values.code) {
      errors.code = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    else if (values.password.length < 8)
      errors.password = 'Password length must be 8 or longer'
    return errors;
  };

  const submit = async (values) => {
    setLoading(true);
    const {code, password} = values;
    try {
      await dataProvider.create(`auth/reset`, {data:{email: userEmail, code, password}})
      setLoading(false);
      redirect('/login');
      //TODO: redirect
    } catch(error) {
      setLoading(false);
      notify(`Error: ${error.body?.error || error}`, 'warning')
    }
  };

  return (
    <div>
      <Form
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.form}>
              <div style={{ textAlign:'center' }}>
                Check your email for the Verification code
              </div>
              <div className={classes.input}>
                <Field
                  autoFocus
                  id="code"
                  name="code"
                  component={Input}
                  label='Verification code'
                  disabled={loading}
                />
              </div>
              <div className={classes.input}>
                <Field
                  id="password"
                  name="password"
                  component={Input}
                  label='New password'
                  type="password"
                  disabled={loading}
                  autoComplete="current-password"
                />
              </div>
            </div>
            <CardActions>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                className={classes.button}
              >
                {loading && (
                  <CircularProgress
                    className={classes.icon}
                    size={18}
                    thickness={2}
                  />
                )}
                Reset Password
              </Button>
            </CardActions>
          </form>
        )}
      />
      <Link href="/#/login" variant="body2" style={{float:'right', marginTop:'1em', marginRight:'1em'}} >
        Cancel
      </Link>
    </div>
  );
};

LoginForm.propTypes = {
  redirectTo: PropTypes.string,
};

export default LoginForm;