import * as React from "react";
import { Route } from 'react-router-dom';
import InviteAccept from './components/InviteAccept';
import PasswordForgot from './components/PasswordForgot'
import PasswordReset from './components/PasswordReset'

const customRoutes = [
  <Route
    exact 
    path="/forgot" 
    component={(props) => <PasswordForgot {...props} />} 
    noLayout
  />,
  <Route
    exact 
    path="/reset"  
    component={(props) => <PasswordReset {...props} />} 
    noLayout
  />,
  <Route 
    exact 
    path="/invites/:id/accept"
    component={(props) => <InviteAccept {...props} />}  
  />,
];

export default customRoutes;