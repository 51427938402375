import dataProvider from './dataProvider'
import {rebuildMenu} from './layout/MyMenu'

export default {
  login: async ({ username, password }) => {
    const response = await dataProvider.login(username, password);
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText)
    }
    let user = response.json
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('token', user.authToken)
    localStorage.setItem('permissions', user.currentAccount.roles)
    rebuildMenu()
    return user ? Promise.resolve() : Promise.reject()
  },
  logout: () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('permissions')
    return Promise.resolve()
  },
  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token') // localStorage.eraseToken(...) 
      return Promise.reject()
    }
    return Promise.resolve();
  },
  checkAuth: params => {
    return localStorage.getItem('token') ? 
      Promise.resolve() : 
      Promise.reject({ message: 'Login required' }) // how to pass values back to controller props?
  },
  getPermissions: () => {
    const role = localStorage.getItem('permissions')
    return role ? Promise.resolve(role) : Promise.resolve('guest') // needs resolve for /forgot to work...
  },
  getCurrentUser: () => {
    const user = JSON.parse(localStorage.getItem('user'))
    return user;// ? Promise.resolve(user) : Promise.reject()
  },
  getIdentity: () => { // personalise the app bar
    const user = localStorage.getItem('user')
    if (!user) 
      return Promise.resolve({ id: null, fullName: 'Guest', avatar: null });
      
    try {
      const { 
        id, 
        name:fullName, 
        avatar //an image source, or a data-uri
      } = JSON.parse(user);
      return Promise.resolve({ id, fullName, avatar });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  hasRole: (roleName, permissions) => { // handles wildcard: hasRole('ws-*')
    let roles = permissions || localStorage.getItem('permissions') || []
    if (!Array.isArray(roles)) roles = roles.split(',')
    for(const role of roles) {
      if(roleName.endsWith('*')) {
        if (role.startsWith(roleName.slice(0, -1)))
          return true
      }
      else if(role === roleName)
        return true
    }
    return false
  },
  refreshUser: async () => {
    const {data} = await dataProvider.get('users/me');
    localStorage.setItem('user', JSON.stringify(data))
  }
}