// Straight copy of RA login form: https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/auth/LoginForm.tsx

import * as React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';

// interface Props {
//   redirectTo?: string;
// }

// interface FormData {
//   username: string;
//   password: string;
// }

const useStyles = makeStyles(
  (theme) => ({//}): Theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaLoginForm' }
);

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const LoginForm = (props) => {//}: Props) => {
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);

  const validate = values => {//}: FormData) => {
    const errors = { username: undefined, password: undefined };

    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  const submit = values => {
    setLoading(true);
    login(values)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
          'warning',
          {
            _:
              typeof error === 'string'
                ? error
                : error && error.message
                  ? error.message
                  : undefined,
          }
        );
      });
  };

  return (
    <div>
      <Form
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.form}>
              <div style={{ textAlign:'center' }}>Enter your Wordsmart App login</div>
              <div className={classes.input}>
                <Field
                  autoFocus
                  id="username"
                  name="username"
                  component={Input}
                  label='Email'
                  disabled={loading}
                />
              </div>
              <div className={classes.input}>
                <Field
                  id="password"
                  name="password"
                  component={Input}
                  label={translate('ra.auth.password')}
                  type="password"
                  disabled={loading}
                  autoComplete="current-password"
                />
              </div>
            </div>
            <CardActions>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                className={classes.button}
              >
                {loading && (
                  <CircularProgress
                    className={classes.icon}
                    size={18}
                    thickness={2}
                  />
                )}
                {translate('ra.auth.sign_in')}
              </Button>
            </CardActions>
          </form>
        )}
      />
      <Link type="reset" href="/#/forgot" variant="body2" style={{float:'right', marginTop:'1em', marginRight:'1em'}} >
        Forgot password ?
      </Link>
    </div>
  );
};

LoginForm.propTypes = {
  redirectTo: PropTypes.string,
};

export default LoginForm;